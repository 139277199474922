<style>

</style>

<script>
import {
  maxLength
} from "vuelidate/lib/validators";

export default {
  name: "BanDescription",
  props: ['banForm'],
  validations: {
    banForm: {
      description: {maxLength: maxLength(400)}
    },
  },
}
</script>
<template>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label for="banFormDescription">
          {{ $t('banmanager.add.details.description.label') }}
          <small>
            {{ $t('banmanager.add.details.description.description') }}
          </small>
        </label>
        <textarea
            id="banFormDescription"
            v-model="banForm.description"
            :class="{ 'is-invalid': $v.banForm.description.$error }"
            :placeholder="$t('banmanager.add.details.description.label')"
            class="form-control"
            rows="4"
            type="text"
            v-on:keyup="$v.banForm.$touch();"
        />
        <div v-if="$v.banForm.description.$error" class="invalid-feedback">
          <span v-if="!$v.banForm.description.maxLength">{{ $t('banmanager.add.details.description.maxlength') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
